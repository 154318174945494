<template>
    <div class="researchAudit-wrapper">
      <div class="researchAudit">
        <div class="time-wrapper">
        <div class="label">日期筛选：</div>
        <el-date-picker
      v-model="selectTime"
      type="date"
      value-format='yyyy-MM-dd'
      size="small"
      placeholder="请选择日期" @change="handleSeletTime">
    </el-date-picker>
      </div>
          <el-table
        :data="post"
        style="width: 100%">
        <el-table-column
      label="序号"
      type="index"
      width="100">
    </el-table-column>
        <el-table-column
        class="title"
        prop="title"
        label="标题"
        show-overflow-tooltip>
        <template slot-scope="{row}">
        <span v-if="row.title">{{row.title}}</span>
        <span v-else>暂无</span>
      </template>
      </el-table-column>
      <el-table-column
        label="作者"  show-overflow-tooltip>
        <template slot-scope="{row}">
          <div class="userInfo">

            <el-avatar  size="small" :src="row.user_info.avatar"></el-avatar>
            <div class="author">{{row.user_info.nickname}}</div>
          </div>
        
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="时间"
        width="180">
      </el-table-column>
      <el-table-column
        label="操作" width="250">
        <template slot-scope="{row}" >
          <div class="news-btn">
            <span class="btn_hover type--text ml-0" @click="handleViewDetail(row)" style="cursor:pointer">查看</span>
            <span v-if="!row.verify_status" class="btn_hover type--text ml-0"  @click="handlePublishPost(row)">发布</span>
            <span v-else class="btn_hover type--text ml-0"  disabled>已发布</span>
            <span  v-if="row.verify_status" class="btn_hover type--text ml-0" @click="handleRevokePost(row)">撤回</span>
            <span  v-else class="btn_hover type--text ml-0" disabled>已撤回</span>
            <span  class="btn_hover type--text ml-0" @click="handleDelPost(row)">删除</span>
          </div>
        </template>
      </el-table-column>
         </el-table>
      </div>
      <div :class="['more', { loading: loading }]" v-loading="loading">
        <span v-show="scrollCount > 2 && more" @click="handleMore">加载更多</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "admin",
  
    data() {
      return {
        more: true,
        scrollCount: 0,
        page: 1,
        loading: false,
        start_time: "",
        limit: 20,
        selectTime:'',
        post: [],
      };
    },
    watch:{
    selectTime(val)
    {
       this.getPost();
    }
  },
    computed: {
      token() {
        return this.$store.state.token;
      },
      userInfo() {
      return this.$store.state.userInfo;
     },
    isDisableAuth()
    {
      return this.userInfo.username =='houwenzhao'
    },
      catogory() {},
    },
    activated() {
      this.loading = false;
      this.more = true;
      this.scrollCount = 0;
      this.getPost();
      window.addEventListener("scroll", this.handleScroll);
    },
    deactivated() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      handleAuthBtn()
    {
      if (this.isDisableAuth) {
     this.$message.warning('该账号没有此权限');
    return false; 
  }
  return true; 
    },
      getPost() {
        this.api
          .postFormAPISM(
            {
              start_time: "",
              limit: this.limit,
              select_date:this.selectTime ?this.selectTime:'', 
            },
            "/admins/getpostarticlelist"
          )
          .then(res => {
            let temp =res.data.data.post_article_list;
            this.post = temp.map(item=>{
                return{
                    ...item,
                    create_time:this.dayjs(item.create_time).format('YYYY-MM-DD HH:mm'),
                }
            })
            console.log("getNews this.post", this.post);
            console.log("getNews", res);
          })
          .catch(error => {
            console.log("error", error);
          });
      },
      handleMore(text) {
        this.page++;
        console.log(this.page);
        this.api
          .postFormAPISM(
            {
              start_time: this.post[this.post.length - 1].create_time
,
              limit: this.limit,
            },
            "/admins/getpostarticlelist"
          )
          .then(res => {
            let temp = res.data.data.post_article_list.map(item=>{
                return{
                    ...item,
                    create_time:this.dayjs(item.create_time).format('YYYY-MM-DD HH:mm'),
                }
            })
            this.post = [...this.post, ...temp];
            this.more = res.data.data.post_article_list.length === this.limit;
            // this.last_time = this.post[this.post.length-1].create_time
;
            console.log("getNews this.post", this.post);
            console.log("getNews", res);
  
            if (text) {
              this.loading = false;
              this.scrollCount++;
            }
          })
          .catch(error => {
            console.log("error", error);
          });
      },
      handleScroll() {
        if (!this.post.length || this.loading || this.scrollCount > 2) return;
  
        // 距顶部
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        // 可视区高度
        var clientHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        // 滚动条总高度
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        let targetHeight = scrollHeight - 257 - 140;
        // console.log(scrollTop, clientHeight, scrollHeight, targetHeight,  targetHeight - scrollTop - clientHeight);
        if (targetHeight - scrollTop - clientHeight < 10) {
          this.loading = true;
          // return
          setTimeout(() => {
            this.handleMore("scroll");
          }, 500);
        }
      },
      handleViewDetail({ id }) {
        let text = this.$router.resolve({
          path: "/postDetail",
          query: {
            id,
          },
        });
        console.log("text", text.href);
        // 打开一个新的页面
        window.open(text.href, "_blank");
      },
      handlePublishPost({ id, verify_status }) {
        this.handleLogin();
        if (!this.token) return;
        if (!this.handleAuthBtn()) return; 
        if (verify_status) {
          this.$message({
            message: "已发布，无需再发布",
            type: "warning",
          });
          return;
        }
        this.api
          .postFormAPISM(
            {
              id,
            },
            "/admins/pulishpostarticle"
          )
          .then(res => {
            this.$message({
              message: "发布成功",
              type: "success",
            });
  
            this.getPost();
            this.$forceUpdate();
          })
          .catch(error => {
            this.$message({
              message: "发布失败",
              type: "warning",
            });
          });
      },
      handleRevokePost({ id, verify_status }) {
        console.log("handleRevokePost", id);
        this.handleLogin();
        if (!this.token) return;
        if (!this.handleAuthBtn()) return; 
        if (!verify_status) {
          this.$message({
            message: "已撤回，无需再撤回",
            type: "warning",
          });
          return;
        }
        this.api.postFormAPISM(
            {
              id,
              id_list: [],
            },
            "/admins/revokepostarticle"
          )
          .then(res => {
            this.$message({
              message: "撤回成功",
              type: "success",
            });
            this.getPost();
            this.$forceUpdate();
          })
          .catch(error => {
            this.$message({
              message: "撤回失败",
              type: "warning",
            });
          });
      },
      handleLogin() {
        if (!this.token) {
          this.$message({
            type: "warning",
            message: "请先登录",
          });
          return this.handleOpenLogin();
        }
      },
      handleOpenLogin() {
        this.$login.show();
      },
      // 删除
      async handleDelPost({ id }) {
        this.handleLogin();
        if (!this.token) return;
        if (!this.handleAuthBtn()) return; 
        let comfirmPromise = new Promise(resolve => {
          this.$confirm("您确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "butText",
            type: "warning",
          })
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              resolve(false);
            });
        });
        let confirm = await comfirmPromise;
        if (!confirm) return;
        this.api
          .postFormAPISM(
            {
              id,
            },
            "/admins/deletepostarticle"
          )
          .then(res => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getPost();
          })
          .catch(error => {
            console.log("error", error);
          });
      },
      handleSeletTime(select_date)
    {

      this.selectTime = select_date;
    }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .researchAudit-wrapper {
    width: 1000px;
    overflow: hidden;
    padding: 37px 0 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    & > .researchAudit {
      &>.time-wrapper{
      display: flex ;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 14px;
      justify-content: flex-end;
      margin-bottom: 50px;
    }
      & .el-table
      {
          & .catogory{
              color: #058e44;
  
          }
          & .userInfo{
            display: flex;
            align-items: center;
            flex-flow: row nowrap;
            &>.author{
              margin-left: 20px;
            }
          }
          & .news-btn {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          font-size: 14px; 
        .btn_hover {
      margin: 0 10px 0 10px ;
      cursor: pointer;
      &:first-of-type{
          margin-left: 0;
      }
        
      &.type--text {
          color: #409EFF;
      }
  
      &:hover {
          color: #02b980;
      }
  
      &.not-allowed,
      &[disabled]{
          color: gray !important;
          cursor: not-allowed !important;
      }
  
  }
      }
      }
     }
    & > .more {
      padding: 10px 0 0;
  
      &.loading {
        padding: 35px 0 0;
      }
  
      & > span {
        display: block;
        padding: 2px 0;
        cursor: pointer;
        width: 296px;
        margin: 0 auto;
        text-align: center;
        font-family: "Arial Normal", "Arial", sans-serif;
        font-weight: 400;
        font-style: normal;
        // font-size: 13px;
        // color: #15a3f0;
        font-size: 14px;
        color: #058e44;
        background-color: rgb(230, 241, 251);
      }
  
      & .el-loading-spinner {
        margin: 0;
        top: 10px;
  
        & .circular {
          height: 26px;
          width: 26px;
        }
      }
    }
  }
  </style>